import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import {
  uriParameterGenerator,
  BOTTOM_ZONE_DATA_LAYERS,
} from 'domains/DataLayer/DataLayerURIGenerator';
import LazyLoadingImage from 'components/LazyLoadingImage/LazyLoadingImage';
import wrapperStyles from '../BottomZone.module.scss';
import styles from './SubscriberExclusivesColumn.module.scss';

const SubscriberExclusivesColumn = ({ articles }) => {
  const heading = 'HBR Subscriber Exclusives';
  const ACCESS_FOR_SUBSCRIBERS = 'Access for subscribers only.';

  const renderSubscriberExclusiveArticles = () => {
    if (isEmpty(articles)) return null;
    return articles.map(({ title, type, uri, image }, i) => {
      return (
        <div className={styles.content} key={uuidv4()}>
          <div className={styles['text-container']}>
            <div>
              <div className={styles.subtitle}>{type}</div>
              <h3>
                <a
                  href={uriParameterGenerator(
                    uri,
                    BOTTOM_ZONE_DATA_LAYERS.HBR_SPECIALS.TEXT,
                    i + 1
                  )}
                  aria-label={title}
                  className={styles.title}
                >
                  {title}
                </a>
              </h3>
            </div>
          </div>
          <div className={styles['image-container']}>
            <LazyLoadingImage
              src={image.uri}
              className={styles['special-image']}
              errorClassName={styles['error-image']}
              redirectTo={uriParameterGenerator(
                uri,
                BOTTOM_ZONE_DATA_LAYERS.HBR_SPECIALS.IMAGE,
                i + 1
              )}
            />
          </div>
        </div>
      );
    });
  };
  return (
    <div
      className={styles['hbr-specials-column']}
      data-testid="hbrSpecialsColumnContainer"
    >
      <div className={styles.heading}>
        <h2 className={wrapperStyles.heading}>{heading}</h2>
        <div>
          <span className={styles['heading-subtitle']}>
            {ACCESS_FOR_SUBSCRIBERS}
          </span>
        </div>
      </div>
      <div
        data-testid="hbrSpecialsColumnArticles"
        className={styles['article-container']}
      >
        {renderSubscriberExclusiveArticles()}
      </div>
    </div>
  );
};

SubscriberExclusivesColumn.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subTitle: PropTypes.string.isRequired,
      image: PropTypes.shape({
        uri: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
};

export default SubscriberExclusivesColumn;
