import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import Thumbnail from 'components/Thumbnail/Thumbnail';
import PropTypes from 'prop-types';
import {
  uriParameterGenerator,
  SPONSOR_CARD_DATA_LAYER,
} from 'domains/DataLayer/DataLayerURIGenerator';
import classNames from 'classnames';
import { stripMarkupFromString } from '../../../../../utilities';
import styles from './SponsorCard.module.scss';

const SponsorCard = ({ article }) => {
  const formattedDek = useMemo(
    () => stripMarkupFromString(article.dek),
    [article.dek]
  );

  const imageToRender = !isEmpty(article.thumbnail?.uri)
    ? article.thumbnail
    : article.image;

  const { positionRank } = article;

  const sponsorClasses = classNames(styles['content-card'], styles.sponsor);
  const thumbnailClasses = classNames(
    styles['card-image'],
    styles['sponsor-image']
  );
  const slugClasses = classNames(styles.slug, styles['sponsor-slug']);

  return (
    <div className={styles['sponsor-container']}>
      <div className={sponsorClasses} data-testid="color-aqua-haze">
        <Thumbnail
          alt={article.title}
          image={imageToRender}
          className={thumbnailClasses}
          errorClassName={styles['sponsor-image-fixed']}
          redirectTo={uriParameterGenerator(
            article.uri,
            SPONSOR_CARD_DATA_LAYER.IMAGE,
            positionRank
          )}
        />

        <div className={styles.dek}>
          {article.type && (
            <span className={slugClasses}>
              <a
                href={uriParameterGenerator(
                  article.uri,
                  SPONSOR_CARD_DATA_LAYER.TEXT,
                  positionRank
                )}
                className={styles['slug-link']}
                aria-label={`Read more about ${article.type}`}
              >
                {article.type}
              </a>
            </span>
          )}
          <h3
            className={styles['head-feed']}
            data-element-type="content-card-element-hover"
          >
            <a
              className={styles['head-feed-link']}
              href={uriParameterGenerator(
                article.uri,
                SPONSOR_CARD_DATA_LAYER.TEXT,
                positionRank
              )}
              aria-label={`Read more about ${article.title}`}
            >
              {article.title}
            </a>
          </h3>
          {/* 
          //TOD:  WORKAROUND, FINAL DESIGNS STILL IN PROGRESS, THIS WILL REFACTOR PRETTY SOON,
          //      FOR NOW, THE DEK FORMATTED IS BEING RENDERED
          {article.type && (
            <span className={styles['sponsor-slug']}>
              <a
                href={uriParameterGenerator(
                  article.topic.uri,
                  SPONSOR_CARD_DATA_LAYER.TEXT,
                  handleIncrement()
                )}
                className={styles['slug-link']}
                aria-label={`Read more about ${article.type}`}
              >
                {article.type}
              </a>
            </span>
          )} */}
          {formattedDek && (
            <span className={styles['slug-dek']}>{formattedDek}</span>
          )}
        </div>
      </div>
    </div>
  );
};

SponsorCard.propTypes = {
  article: PropTypes.shape({
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    dek: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    image: PropTypes.shape({
      uri: PropTypes.string.isRequired,
    }),
    thumbnail: PropTypes.shape({
      uri: PropTypes.string.isRequired,
    }),
    topic: PropTypes.shape({
      name: PropTypes.string,
      uri: PropTypes.string,
    }),
    positionRank: PropTypes.number.isRequired,
  }).isRequired,
};

export default SponsorCard;
