import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import SubscriptionModal from 'mfe-core/ui/SubscriptionModal';

const SiteLicensingModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const router = useRouter();
  const hasContract = 'contractId' in router.query;

  useEffect(() => {
    window.tp = window.tp || [];
    if (hasContract) {
      window.tp.push([
        'addHandler',
        'loginRequired',
        (params) => {
          setIsModalOpen(true);
          window.tp.checkoutParams = params;
        },
      ]);
    }
  }, []);

  const pianoCallbackHandler = (user, completeSubscriptionActivation) => {
    window.tp.push(['setUserRef', user]);

    window.tp.push([
      'init',
      () => {
        if (window.tp.user.isUserValid()) {
          window.tp.offer.startCheckout(window.tp.checkoutParams);

          setTimeout(async () => {
            // setTimeout is required here since we do not have any callback associated with startCheckout for now.
            try {
              await completeSubscriptionActivation();
            } catch (error) {
              console.log(error); // There wasn't any error handling in legacy app. This is in discussion with PO.
            }
          }, 2000);
        }
      },
    ]);
  };

  const modalCloseHandler = () => {
    setIsModalOpen(false);
  };

  return (
    isModalOpen && (
      <SubscriptionModal
        onClose={modalCloseHandler}
        pianoCallback={pianoCallbackHandler}
      />
    )
  );
};

export default SiteLicensingModal;
