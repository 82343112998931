import { useEffect, useState } from 'react';

const useWindowResize = () => {
  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);

  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  };

  // Set windowSizes on component mount
  useEffect(() => {
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    // Set eventListener on component mount
    window.addEventListener('resize', setWindowDimensions);

    // Remove eventListener on component unmount
    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    };
  }, []);

  return { windowWidth, windowHeight };
};

export default useWindowResize;
