import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { deleteCookie, triggerCustomEvent, readCookie } from '../../utilities';
import styles from './SubscriberOnboarding.module.scss';

const SubscriberOnboarding = ({ userRefToken }) => {
  const [onboardCookie, setOnboardCookie] = useState('');
  const [isSubsOnboarding, setSubsOnboarding] = useState(false);

  const subsOnboardClasses = classNames({
    [styles['subs-onboarding-overlay']]: isSubsOnboarding,
    [styles['subs-onboarding-overlay-not-show']]: !isSubsOnboarding,
  });

  const getOnboardCookie = () => {
    const cookie = readCookie('_pc_pso');
    setOnboardCookie(cookie);
  };

  const handleMessageEvents = () => {
    // Placeholder to listen messages from Iframe
    window.onmessage = function (e) {
      const bodyElement = document.getElementsByTagName('body')[0];

      if (e.data.type === 'subscriber-onboarding') {
        setSubsOnboarding(true);
        bodyElement.className += ' overflow-for-subs-onboarding';
      }

      if (e.data.type === 'close-subscriber-onboarding') {
        setSubsOnboarding(false);
        bodyElement.className = bodyElement.className.replace(
          'overflow-for-subs-onboarding',
          ''
        );
        deleteCookie('_pc_pso');
        setOnboardCookie('');
      }

      if (e.data.type === 'link') {
        window.open(e.data.destiny, e.data.target);
      }

      if (e.data.type === 'analytics') {
        triggerCustomEvent(e.data.eventName, {
          screenName: e.data.screenName,
          subTier: onboardCookie,
        });
      }
    };
  };

  const initializePiano = () => {
    window.tp = window.tp || [];
    window.tp.push(['setUserRef', userRefToken]);
    window.tp.push([
      'init',
      () => {
        window.tp.experience.init();
      },
    ]);
    handleMessageEvents();
  };

  useEffect(() => {
    if (userRefToken) {
      getOnboardCookie();
      initializePiano();
    }
  }, []);

  return <section id="subscriber-onboarding" className={subsOnboardClasses} />;
};

SubscriberOnboarding.propTypes = {
  userRefToken: PropTypes.string.isRequired,
};

export default SubscriberOnboarding;
