import { useMemo } from 'react';
import { includes, isEmpty, lowerCase } from 'lodash';
import Thumbnail from 'components/Thumbnail/Thumbnail';
import PropTypes from 'prop-types';
import {
  uriParameterGenerator,
  LATEST_DATA_LAYER,
} from 'domains/DataLayer/DataLayerURIGenerator';
import { formatAuthors } from '../../../../utilities';
import { MEDIA_TYPES } from '../../../../constants';
import styles from './LatestCard.module.scss';

const LatestCard = ({ article, positionRank }) => {
  const formattedAuthors = useMemo(
    () => formatAuthors(article.authors),
    [article.authors]
  );

  const renderArticleTypeTag = () => {
    const { type } = article;

    if (type && includes(MEDIA_TYPES, lowerCase(type))) {
      return (
        <div className={styles.slug}>
          <span>{article.type}</span>
        </div>
      );
    }

    return null;
  };

  const imageToRender = !isEmpty(article.thumbnail?.uri)
    ? article.thumbnail
    : article.image;

  return (
    <div className={styles['latest-article']}>
      <div className={styles.text}>
        {renderArticleTypeTag()}
        <h2 className={styles.title}>
          <a
            href={uriParameterGenerator(
              article.uri,
              LATEST_DATA_LAYER.TEXT,
              positionRank
            )}
            className={styles.link}
            data-element-type="latest-card-element-hover"
            aria-label={`Read more about ${article.title}`}
          >
            {article.title}
          </a>
        </h2>

        {!isEmpty(formattedAuthors) && (
          <span className={styles.author}>
            <a
              href={uriParameterGenerator(
                article.uri,
                LATEST_DATA_LAYER.TEXT,
                positionRank
              )}
              className={styles.link}
            >
              {formattedAuthors}
            </a>
          </span>
        )}
      </div>
      <Thumbnail
        alt={article.type}
        image={imageToRender}
        redirectTo={uriParameterGenerator(
          article.uri,
          LATEST_DATA_LAYER.IMAGE,
          positionRank
        )}
        className={styles['article-image']}
        errorClassName={styles['fixed-image']}
      />
    </div>
  );
};

LatestCard.propTypes = {
  article: PropTypes.shape({
    authors: PropTypes.arrayOf(PropTypes.string).isRequired,
    image: PropTypes.shape({
      uri: PropTypes.string.isRequired,
    }),
    thumbnail: PropTypes.shape({
      uri: PropTypes.string.isRequired,
    }),
    topic: PropTypes.shape({
      name: PropTypes.string,
      uri: PropTypes.string,
    }),
    dek: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    uri: PropTypes.string,
  }).isRequired,
  positionRank: PropTypes.number.isRequired,
};

export default LatestCard;
