import { useEffect, useState } from 'react';
import { FEATURED_IMAGE_RATIO_ASPECT } from '../constants';

const DEFAULT_HEIGHT = 204.44;
const MIN_WIDTH_TO_ADJUST_RATIO = 375;

const useImageAspectRatio = () => {
  const [windowHeight, setWindowHeight] = useState(-1);

  const setWindowDimensions = () => {
    const currentWidth = window.innerWidth;
    let currentHeight = DEFAULT_HEIGHT;
    if (currentWidth >= MIN_WIDTH_TO_ADJUST_RATIO) {
      for (let idx = 0; idx < FEATURED_IMAGE_RATIO_ASPECT.length; idx += 1) {
        const [viewPortWidthLimit, viewPortRange] =
          FEATURED_IMAGE_RATIO_ASPECT[idx];
        if (currentWidth < viewPortWidthLimit) {
          const widthPercentage =
            (currentWidth - viewPortRange.MIN_WIDTH) /
            (viewPortRange.MAX_WIDTH - viewPortRange.MIN_WIDTH);

          currentHeight =
            viewPortRange.MIN_HEIGHT +
            (viewPortRange.MAX_HEIGHT - viewPortRange.MIN_HEIGHT) *
              widthPercentage;
          break;
        }
      }
    }
    setWindowHeight(currentHeight);
  };

  useEffect(() => {
    setWindowDimensions();
    window.addEventListener('resize', setWindowDimensions);
    return () => {
      window.removeEventListener('resize', setWindowDimensions);
    };
  }, []);

  return { windowHeight };
};

export default useImageAspectRatio;
