import useAuth from 'mfe-core/hooks/useAuth';
import SVGIcon from 'mfe-core/ui/SVGIcon';
import {
  NEWSLETTER_LOGGED_USER,
  NEWSLETTER_NON_LOGGED_USER,
} from '../../../../constants';

import styles from './NewsletterTout.module.scss';

const NewsletterTout = () => {
  const { isAuthenticated } = useAuth();
  const newsletter = isAuthenticated
    ? NEWSLETTER_LOGGED_USER
    : NEWSLETTER_NON_LOGGED_USER;
  const { title, text, icon, url } = newsletter;

  const signUpText = 'Sign Up';

  return (
    <div className={styles['newsletter-tout']}>
      <div>
        <p className={styles['description-text']}>
          Sign up for <strong>{title}</strong> {` - ${text}`}
        </p>
        <a
          className={styles['sign-up-link']}
          href={url}
          data-element-type="newsletter-tout-element-hover"
          target="_blank"
          rel="noopener noreferrer"
        >
          {signUpText}
          <SVGIcon variant={SVGIcon.variants.CIRCLE_CHEVRON_RIGHT} />
        </a>
      </div>
      <div className={styles['newslettertout-icon']}>
        <SVGIcon className={styles['svg-icon']} variant={icon} />
      </div>
    </div>
  );
};

export default NewsletterTout;
