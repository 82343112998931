import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import Thumbnail from 'components/Thumbnail/Thumbnail';
import PropTypes from 'prop-types';
import {
  uriParameterGenerator,
  FOR_YOU_DATA_LAYER,
} from 'domains/DataLayer/DataLayerURIGenerator';
import { formatAuthors, stripMarkupFromString } from '../../../../utilities';
import styles from './ForYouCard.module.scss';

const ForYouCard = ({ article, positionRank }) => {
  const formattedAuthors = useMemo(
    () => formatAuthors(article.authors),
    [article.authors]
  );

  const formattedDek = useMemo(
    () => stripMarkupFromString(article.dek),
    [article.dek]
  );
  const imageToRender = !isEmpty(article.thumbnail?.uri)
    ? article.thumbnail
    : article.image;

  return (
    <div className={styles['for-you-article']}>
      <Thumbnail
        alt={article.type}
        image={imageToRender}
        redirectTo={uriParameterGenerator(
          article.uri,
          FOR_YOU_DATA_LAYER.IMAGE,
          positionRank
        )}
        className={styles['article-image']}
        errorClassName={styles['fixed-image']}
        lazyLoad={false}
      />

      <div className={styles.text}>
        {!isEmpty(article.topic) && (
          <span className={styles.topic}>
            {/* TODO: Replace the link with the topic's one, once it's added to the article object */}
            <a
              href={uriParameterGenerator(
                article.topic.uri,
                FOR_YOU_DATA_LAYER.TEXT,
                positionRank
              )}
              className={styles.link}
              aria-label={`Read more about ${article.topic.name}`}
            >
              {article.topic.name}
            </a>
          </span>
        )}

        <h2 className={styles.title}>
          <a
            href={uriParameterGenerator(
              article.uri,
              FOR_YOU_DATA_LAYER.TEXT,
              positionRank
            )}
            className={styles.link}
            data-element-type="for-you-card-element-hover"
            aria-label={`Read more about ${article.title}`}
          >
            {article.title}
          </a>
        </h2>

        {!isEmpty(article.dek) && (
          <p className={styles.dek}>
            <a
              href={uriParameterGenerator(
                article.uri,
                FOR_YOU_DATA_LAYER.TEXT,
                positionRank
              )}
              className={styles.link}
            >
              {formattedDek}
            </a>
          </p>
        )}

        {!isEmpty(formattedAuthors) && (
          <p className={styles.author}>
            <a
              href={uriParameterGenerator(
                article.uri,
                FOR_YOU_DATA_LAYER.TEXT,
                positionRank
              )}
              className={styles.link}
            >
              {formattedAuthors}
            </a>
          </p>
        )}
      </div>
    </div>
  );
};

ForYouCard.defaultProps = {
  article: {},
};

ForYouCard.propTypes = {
  article: PropTypes.shape({
    authors: PropTypes.arrayOf(PropTypes.string).isRequired,
    image: PropTypes.shape({
      uri: PropTypes.string.isRequired,
    }),
    thumbnail: PropTypes.shape({
      uri: PropTypes.string.isRequired,
    }),
    topic: PropTypes.shape({
      name: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }),
    dek: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    uri: PropTypes.string,
  }),
  positionRank: PropTypes.number.isRequired,
};

export default ForYouCard;
