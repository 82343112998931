import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty } from 'lodash';
import ComponentLoadFailure from 'components/ComponentLoadFailure/ComponentLoadFailure';
import LatestRow from './LatestRow/LatestRow';
import styles from './LatestZone.module.scss';
import { STATUS_CODE_SUCCESS } from '../../constants';
import useUserSpecificContent from '../../hooks/useUserSpecificContent';

const LatestZone = ({
  latestArticles,
  sponsorArticles,
  trendingArticle,
  followedArticle,
  statuses,
}) => {
  const CARDS_PER_ROW = 4;
  const MAX_ROWS = 2;

  const {
    articles: followedTopicsUserArticles,
    statuses: userArticleStatuses,
  } = useUserSpecificContent({ followedArticle }, statuses);
  const [statusSuccess, setStatusSuccess] = useState(true);

  useEffect(() => {
    const initialValue = true;
    const currentStatus = userArticleStatuses.reduce(
      (accumulator, statusObj) => {
        return accumulator
          ? statusObj['status-code'] === STATUS_CODE_SUCCESS
          : false;
      },
      initialValue
    );

    setStatusSuccess(currentStatus);
  }, [userArticleStatuses]);

  const renderLatestRows = () => {
    // Reduce the latest articles array to only 8 items.
    const latestArticlesMax = !isEmpty(latestArticles)
      ? latestArticles.slice(0, 8)
      : [];

    const articlesToRender = [...latestArticlesMax];

    articlesToRender.splice(0, 3);
    articlesToRender.splice(4, 0, trendingArticle);
    articlesToRender.splice(5, 0, {
      ...followedTopicsUserArticles,
      isfollowedTopicsUserArticles: true,
    });

    if (sponsorArticles && sponsorArticles[0]) {
      articlesToRender.splice(6, 0, sponsorArticles[0]);
    }

    const articlesChunks = articlesToRender.reduce((chunk, article, index) => {
      const articleChunk = chunk;
      const chunkIndex = Math.floor(index / CARDS_PER_ROW);
      if (!articleChunk[chunkIndex]) {
        articleChunk[chunkIndex] = [];
      }
      articleChunk[chunkIndex].push({ ...article, positionRank: index + 1 });
      return articleChunk;
    }, []);

    const articles = articlesChunks.map((chunk, index) =>
      index <= MAX_ROWS - 1 ? (
        <LatestRow key={uuidv4()} articles={chunk} />
      ) : null
    );

    return articles;
  };
  return (
    <section className={styles['latest-zone']}>
      {!statusSuccess ? <ComponentLoadFailure /> : <>{renderLatestRows()}</>}
    </section>
  );
};

LatestZone.propTypes = {
  latestArticles: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      image: PropTypes.shape({
        uri: PropTypes.string,
      }),
      authors: PropTypes.arrayOf(PropTypes.string),
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
  sponsorArticles: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      dek: PropTypes.string.isRequired,
      image: PropTypes.shape({
        sizes: PropTypes.string,
        srcset: PropTypes.string,
        uri: PropTypes.string,
      }),
      topic: PropTypes.shape({
        name: PropTypes.string.isRequired,
        uri: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  trendingArticle: PropTypes.shape({
    type: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    dek: PropTypes.string.isRequired,
    authors: PropTypes.arrayOf(PropTypes.string).isRequired,
    image: PropTypes.shape({
      sizes: PropTypes.string,
      srcset: PropTypes.string,
      uri: PropTypes.string,
    }),
    topic: PropTypes.shape({
      name: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }),
  }).isRequired,
  followedArticle: PropTypes.shape({
    type: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    dek: PropTypes.string.isRequired,
    authors: PropTypes.arrayOf(PropTypes.string).isRequired,
    image: PropTypes.shape({
      sizes: PropTypes.string,
      srcset: PropTypes.string,
      uri: PropTypes.string,
    }),
    topic: PropTypes.shape({
      name: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }),
  }).isRequired,
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      'status-code': PropTypes.number,
      message: PropTypes.string,
    })
  ).isRequired,
};

export default LatestZone;
