/*
Compound Components Strategy
============================
- Index file should add additional data to the pure component (Redux, MobX, Selectors, etc), create/call the ComponentContainer.
- It is also possible to add the children stratety as compound component here, <root> {children} </root>
- For now this component does not use anything, so, index will return the same object.
*/

import ForYou from './ForYou';

export default ForYou;
