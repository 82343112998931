import SVGIcon from 'mfe-core/ui/SVGIcon';
import styles from './ComponentLoadFailure.module.scss';
import { COMPONENT_ERROR_MESSAGE } from '../../constants/index';

const IconVariant = SVGIcon.variants;

const ComponentLoadFailure = () => {
  return (
    <div className={styles['component-fail']} data-testid="errorContainer">
      <p className={styles.message}>
        <SVGIcon
          variant={IconVariant.FACE_UNAMUSED}
          className={styles['message-icon']}
        />
        {COMPONENT_ERROR_MESSAGE}
      </p>
    </div>
  );
};

export default ComponentLoadFailure;
