import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import Thumbnail from 'components/Thumbnail/Thumbnail';
import {
  handleIncrement,
  topicZonePositionCounter,
  uriParameterGenerator,
  LIST_BOX_DATA_LAYER,
  TOPIC_INSIGHT_CENTER_DATA_LAYER,
} from 'domains/DataLayer/DataLayerURIGenerator';
import PropTypes from 'prop-types';
import styles from './ListBox.module.scss';

const ListBox = ({ topic, position, index }) => {
  const firstArticle = topic.contents ? topic.contents[0] : {};
  const firstArticleUri = firstArticle?.topic?.uri || '';
  const dataLayerText = topic.isInsightCenter
    ? TOPIC_INSIGHT_CENTER_DATA_LAYER.TEXT
    : LIST_BOX_DATA_LAYER.TEXT;

  const imageToRender = !isEmpty(firstArticle?.thumbnail?.uri)
    ? firstArticle.thumbnail
    : firstArticle?.image;

  const renderTopicItems = () => {
    const articles = topic.contents.map(({ uri, title }, i) => (
      <h3
        key={uuidv4()}
        className={styles['topic-title']}
        data-element-type={i === 0 ? 'content-box-element-hover' : null}
      >
        <a
          className={styles['topic-title-link']}
          href={uriParameterGenerator(
            uri,
            dataLayerText,
            handleIncrement(topicZonePositionCounter)
          )}
          aria-label={`Read more about ${title}`}
        >
          {title}
        </a>
      </h3>
    ));

    return articles;
  };

  const topicLink = () => {
    if (topic.isInsightCenter) {
      return uriParameterGenerator(
        topic.heading?.uri,
        dataLayerText,
        handleIncrement(topicZonePositionCounter)
      );
    }

    return !firstArticleUri
      ? ':'
      : uriParameterGenerator(
          firstArticleUri,
          dataLayerText,
          handleIncrement(topicZonePositionCounter)
        );
  };

  return (
    <div
      className={styles['content-box']}
      data-element-position={position}
      id={`content-box-${index}`}
    >
      <span className={styles.slug}>
        <a
          href={topicLink()}
          className={styles['slug-link']}
          aria-label={`Read more about ${topic.heading?.text}`}
        >
          {topic.heading?.text}
        </a>
      </span>

      <Thumbnail
        alt={topic.heading?.text}
        image={imageToRender}
        redirectTo={uriParameterGenerator(
          firstArticle?.uri,
          topic.isInsightCenter
            ? TOPIC_INSIGHT_CENTER_DATA_LAYER.IMAGE
            : LIST_BOX_DATA_LAYER.IMAGE,
          handleIncrement(topicZonePositionCounter, true)
        )}
        className={styles['topic-image']}
      />

      <div className={styles.topics}>
        {!isEmpty(topic.contents) && renderTopicItems()}
      </div>

      {topic.cta && (
        <div className={styles['sponsored-by']}>
          <span className={styles['text-sponsored-by']}>{topic.cta?.text}</span>
          <img
            className={styles['icon-sponsored-by']}
            alt=""
            src={topic.cta?.image?.uri}
          />
        </div>
      )}
    </div>
  );
};

ListBox.propTypes = {
  topic: PropTypes.shape({
    heading: PropTypes.shape({
      text: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }).isRequired,
    contents: PropTypes.arrayOf(
      PropTypes.shape({
        uri: PropTypes.string.isRequired,
        type: PropTypes.string,
        image: PropTypes.shape({
          uri: PropTypes.string.isRequired,
        }),
        thumbnail: PropTypes.shape({
          uri: PropTypes.string.isRequired,
        }),
        topic: PropTypes.shape({
          uri: PropTypes.string,
        }),
      })
    ),
    cta: PropTypes.shape({
      text: PropTypes.string.isRequired,
      image: PropTypes.shape({
        uri: PropTypes.string.isRequired,
      }),
    }),
    isInsightCenter: PropTypes.bool,
  }).isRequired,
  position: PropTypes.string,
  index: PropTypes.number.isRequired,
};

ListBox.defaultProps = {
  position: undefined,
};

export default ListBox;
