import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { isArray, isEmpty } from 'lodash';
import styles from './ContentRow.module.scss';
import ContentRowTile from './ContentRowTile/ContentRowTile';
import ListTile from './ListTile/ListTile';
import ComponentLoadFailure from '../ComponentLoadFailure/ComponentLoadFailure';

const ContentRow = ({ tiles }) => {
  if (isEmpty(tiles)) return null;
  const renderTiles = () => {
    const tilesToRender = tiles.map((tile) => {
      const { content, items } = tile;
      if (isEmpty(content) && isEmpty(items)) return <ComponentLoadFailure />;
      if (isArray(items)) return <ListTile tile={tile} key={uuidv4()} />;
      return <ContentRowTile tile={tile} key={uuidv4()} />;
    });
    return tilesToRender;
  };

  return (
    <section
      className={styles['content-row-container']}
      data-testid="tiles-container"
    >
      {renderTiles()}
    </section>
  );
};

ContentRow.propTypes = {
  tiles: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.shape({
        text: PropTypes.string,
        slug: PropTypes.shape({
          text: PropTypes.string,
        }),
      }),
      contents: PropTypes.arrayOf([
        PropTypes.shape({
          type: PropTypes.string,
          uri: PropTypes.string,
          title: PropTypes.string,
          dek: PropTypes.string,
          image: PropTypes.shape({
            sizes: PropTypes.string,
            srcset: PropTypes.string,
            uri: PropTypes.string,
          }),
          thumbnail: PropTypes.shape({
            sizes: PropTypes.string,
            srcset: PropTypes.string,
            uri: PropTypes.string,
          }),
        }),
      ]),
      cta: PropTypes.shape({
        text: PropTypes.string,
        uri: PropTypes.string,
      }),
    })
  ).isRequired,
};

export default ContentRow;
