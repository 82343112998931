import PropTypes from 'prop-types';
import DigitalArticleCard from './DigitalArticleCard/DigitalArticleCard';
import SponsorCard from './SponsorCard/SponsorCard';
import MediaCard from './MediaCard/MediaCard';

const ContentCard = ({ article, position }) => {
  const renderCard = (digitalArticle) => {
    const cardsMap = {
      'Sponsor Content': () => <SponsorCard article={digitalArticle} />,
      Video: () => <MediaCard article={digitalArticle} position={position} />,
      Audio: () => <MediaCard article={digitalArticle} position={position} />,
      Ascend: () => (
        <DigitalArticleCard article={digitalArticle} position={position} />
      ),
      'Digital Article': () => (
        <DigitalArticleCard article={digitalArticle} position={position} />
      ),
      'Magazine Article': () => (
        <DigitalArticleCard article={digitalArticle} position={position} />
      ),
      default: () => null,
    };

    return (cardsMap[digitalArticle?.type] || cardsMap.default)();
  };

  return renderCard(article);
};

ContentCard.propTypes = {
  article: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  position: PropTypes.string,
};

ContentCard.defaultProps = {
  position: undefined,
};

export default ContentCard;
