import Thumbnail from 'components/Thumbnail/Thumbnail';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
  uriParameterGenerator,
  MEDIA_CARD_DATA_LAYER,
} from 'domains/DataLayer/DataLayerURIGenerator';
import styles from './MediaCard.module.scss';

const MediaCard = ({ article, position }) => {
  const imageToRender = !isEmpty(article.thumbnail?.uri)
    ? article.thumbnail
    : article.image;

  const { positionRank } = article;

  return (
    <div className={styles['content-card']} data-element-position={position}>
      <Thumbnail
        alt={article.title}
        image={imageToRender}
        className={styles['card-image']}
        errorClassName={styles['card-image-fixed']}
        redirectTo={uriParameterGenerator(
          article.uri,
          MEDIA_CARD_DATA_LAYER.IMAGE,
          positionRank
        )}
      />

      <div className={styles.dek}>
        <span className={styles.slug}>
          <a
            href={uriParameterGenerator(
              article.uri,
              MEDIA_CARD_DATA_LAYER.TEXT,
              positionRank
            )}
            className={styles['media-slug-link']}
            aria-label={`Read more about ${article.type}`}
          >
            {article.type}
          </a>
        </span>
        <h3
          className={styles['head-feed']}
          data-element-type="content-card-element-hover"
        >
          <a
            className={styles['head-feed-link']}
            href={uriParameterGenerator(
              article.uri,
              MEDIA_CARD_DATA_LAYER.TEXT,
              positionRank
            )}
            aria-label={`Read more about ${article.title}`}
          >
            {article.title}
          </a>
        </h3>
      </div>
    </div>
  );
};

MediaCard.propTypes = {
  article: PropTypes.shape({
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    image: PropTypes.shape({
      uri: PropTypes.string.isRequired,
    }),
    thumbnail: PropTypes.shape({
      uri: PropTypes.string.isRequired,
    }),
    positionRank: PropTypes.number.isRequired,
  }).isRequired,
  position: PropTypes.number,
};

MediaCard.defaultProps = {
  position: undefined,
};

export default MediaCard;
