import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import {
  uriParameterGenerator,
  BOTTOM_ZONE_DATA_LAYERS,
} from 'domains/DataLayer/DataLayerURIGenerator';
import SVGIcon from 'mfe-core/ui/SVGIcon';
import PropTypes from 'prop-types';
import LazyLoadingImage from '../../LazyLoadingImage/LazyLoadingImage';
import wrapperStyles from '../BottomZone.module.scss';
import styles from './PodcastColumn.module.scss';

const PodcastColumn = ({ podcasts, cta }) => {
  const HEADING = 'Podcasts';

  const renderPodcasts = () => {
    if (isEmpty(podcasts)) return null;

    return podcasts.map((podcast, i) => {
      return (
        <div className={styles.podcast} key={uuidv4()}>
          <div
            className={styles['podcast-icon']}
            data-testid={`podcast-icon-wrapper-${i}`}
          >
            <LazyLoadingImage
              className={styles['podcast-show-logo']}
              src={podcast['podcast-logo-uri']}
              alt={podcast['podcast-series-title']}
            />
          </div>
          <div className={styles['podcast-content']}>
            <span className={styles['podcast-topic']}>
              {podcast?.topic?.name}
            </span>
            <h3 className={styles['podcast-title']}>
              <a
                href={uriParameterGenerator(
                  podcast.uri,
                  BOTTOM_ZONE_DATA_LAYERS.PODCAST.TEXT,
                  i + 1
                )}
                className={styles['podcast-title-link']}
              >
                {podcast.title}
              </a>
            </h3>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={styles['podcasts-column']}>
      <div className={styles['heading-container']}>
        <h2 className={wrapperStyles.heading}>{HEADING}</h2>
        <a
          className={styles['more-podcasts-btn']}
          href={uriParameterGenerator(
            cta.uri,
            BOTTOM_ZONE_DATA_LAYERS.PODCAST.SEE_MORE
          )}
        >
          {cta.text}
          <SVGIcon
            className={styles['more-podcasts-icon']}
            variant={SVGIcon.variants.ARROW_RIGHT_NAV}
          />
        </a>
      </div>
      <div data-testid="podcasts-wrapper">{renderPodcasts()}</div>
    </div>
  );
};

PodcastColumn.propTypes = {
  podcasts: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      uri: PropTypes.string,
      title: PropTypes.string,
      dek: PropTypes.string,
      'podcast-series-title': PropTypes.string,
      'published-date': PropTypes.string,
      'podcast-logo-uri': PropTypes.string,
      topic: PropTypes.shape({
        name: PropTypes.string,
        uri: PropTypes.string,
      }),
    })
  ).isRequired,
  cta: PropTypes.shape({
    text: PropTypes.string,
    uri: PropTypes.string,
  }).isRequired,
};

export default PodcastColumn;
