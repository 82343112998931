import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import {
  uriParameterGenerator,
  DIGITAL_ARTICLE_DATA_LAYER,
  FOLLOWED_ARTICLE_DATA_LAYER,
} from 'domains/DataLayer/DataLayerURIGenerator';
import Thumbnail from 'components/Thumbnail/Thumbnail';
import { formatAuthors } from '../../../../../utilities';
import styles from './DigitalArticleCard.module.scss';

const DigitalArticleCard = ({ article, position }) => {
  const formattedAuthors = useMemo(
    () => formatAuthors(article.authors),
    [article.authors]
  );

  const { positionRank } = article;

  const imageToRender = !isEmpty(article.thumbnail?.uri)
    ? article.thumbnail
    : article.image;

  return (
    <div className={styles['content-card']} data-element-position={position}>
      <Thumbnail
        alt={article.title}
        image={imageToRender}
        redirectTo={uriParameterGenerator(
          article.uri,
          article.isFollowedArticle
            ? FOLLOWED_ARTICLE_DATA_LAYER.IMAGE
            : DIGITAL_ARTICLE_DATA_LAYER.IMAGE,
          positionRank
        )}
        className={styles['card-image']}
        errorClassName={styles['card-image-fixed']}
      />

      <div className={styles.dek}>
        <h3
          className={styles['head-feed']}
          data-element-type="content-card-element-hover"
        >
          <a
            className={styles['head-feed-link']}
            href={uriParameterGenerator(
              article.uri,
              article.isFollowedArticle
                ? FOLLOWED_ARTICLE_DATA_LAYER.TEXT
                : DIGITAL_ARTICLE_DATA_LAYER.TEXT,
              positionRank
            )}
            aria-label={`Read more about ${article.title}`}
          >
            {article.title}
          </a>
        </h3>
        {!isEmpty(formattedAuthors) && (
          <span>
            <a
              className={styles['byline-link']}
              href={uriParameterGenerator(
                article.uri,
                article.isFollowedArticle
                  ? FOLLOWED_ARTICLE_DATA_LAYER.TEXT
                  : DIGITAL_ARTICLE_DATA_LAYER.TEXT,
                positionRank
              )}
            >
              {formattedAuthors}
            </a>
          </span>
        )}
      </div>
    </div>
  );
};

DigitalArticleCard.propTypes = {
  article: PropTypes.shape({
    authors: PropTypes.arrayOf(PropTypes.string).isRequired,
    image: PropTypes.shape({
      uri: PropTypes.string.isRequired,
    }),
    thumbnail: PropTypes.shape({
      uri: PropTypes.string.isRequired,
    }),
    topic: PropTypes.shape({
      name: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }),
    dek: PropTypes.string,
    title: PropTypes.string,
    type: PropTypes.string,
    uri: PropTypes.string,
    position: PropTypes.number.isRequired,
    isFollowedArticle: PropTypes.bool,
    positionRank: PropTypes.number.isRequired,
  }).isRequired,
  position: PropTypes.string,
};

DigitalArticleCard.defaultProps = {
  position: undefined,
};

export default DigitalArticleCard;
