import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import useUser from 'mfe-core/hooks/useUser';
import useSubscriberStore from '../stores/subscriber/subscriber.store';

const useUserSpecificContent = (loggedOutContent, loggedOutContentStatuses) => {
  const sectionKey = Object.keys(loggedOutContent)[0].toString();

  const { profile } = useUser();
  const { subscriberContent } = useSubscriberStore();
  const [articles, setArticles] = useState(loggedOutContent[sectionKey]);
  const [statuses, setStatuses] = useState(loggedOutContentStatuses);

  useEffect(() => {
    const subscriberArticles = subscriberContent[sectionKey];
    if (profile && !isEmpty(subscriberArticles)) {
      setArticles(subscriberArticles.content);

      const updatedStatuses = statuses.map((status) => {
        if (status.subscriberSwapKey === sectionKey) {
          return subscriberArticles.status;
        }
        return status;
      });

      setStatuses(updatedStatuses);
    } else {
      setArticles(loggedOutContent[sectionKey]);
      setStatuses(loggedOutContentStatuses);
    }
  }, [profile, subscriberContent]);

  return { articles, statuses };
};

export default useUserSpecificContent;
