import PropTypes from 'prop-types';
import PopularColumn from './PopularColumn/PopularColumn';
import NewslettersColumn from './NewslettersColumn/NewslettersColumn';
import SubscriberExclusivesColumn from './SubscriberExclusivesColumn/SubscriberExclusivesColumn';
import PodcastColumn from './PodcastColumn/PodcastColumn';
import styles from './BottomZone.module.scss';

const BottomZone = ({
  popularArticles,
  newsletters,
  subscriberExclusives,
  podcasts,
}) => {
  return (
    <section className={styles['bottom-zone']}>
      <PopularColumn articles={popularArticles.contents} />
      <NewslettersColumn
        newsletters={newsletters.contents}
        cta={newsletters.cta}
      />
      <SubscriberExclusivesColumn articles={subscriberExclusives.contents} />
      <PodcastColumn podcasts={podcasts.contents} cta={podcasts.cta} />
    </section>
  );
};

// TODO: Modify props to match with what should be rendered on the columns
BottomZone.propTypes = {
  popularArticles: PropTypes.shape({
    contents: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        uri: PropTypes.string,
        title: PropTypes.string,
      })
    ),
    cta: PropTypes.shape({
      text: PropTypes.string,
      uri: PropTypes.string,
    }),
  }).isRequired,
  newsletters: PropTypes.shape({
    contents: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        title: PropTypes.string,
        dek: PropTypes.string,
        image: PropTypes.shape({
          sizes: PropTypes.string,
          uri: PropTypes.string,
        }),
        cta: PropTypes.shape({
          text: PropTypes.string,
          uri: PropTypes.string,
        }),
      })
    ),
    cta: PropTypes.shape({
      text: PropTypes.string,
      uri: PropTypes.string,
    }),
  }).isRequired,
  subscriberExclusives: PropTypes.shape({
    contents: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        title: PropTypes.string,
        uri: PropTypes.string,
        image: PropTypes.shape({
          sizes: PropTypes.string,
          uri: PropTypes.string,
        }),
      })
    ),
  }).isRequired,
  podcasts: PropTypes.shape({
    contents: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        uri: PropTypes.string,
        title: PropTypes.string,
        dek: PropTypes.string,
        'podcast-series-title': PropTypes.string,
        'published-date': PropTypes.string,
        'podcast-logo-uri': PropTypes.string,
        topic: PropTypes.shape({
          name: PropTypes.string,
          uri: PropTypes.string,
        }),
      })
    ),
    cta: PropTypes.shape({
      text: PropTypes.string,
      uri: PropTypes.string,
    }),
  }).isRequired,
};

export default BottomZone;
