import classNames from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import {
  uriParameterGenerator,
  BOTTOM_ZONE_DATA_LAYERS,
} from 'domains/DataLayer/DataLayerURIGenerator';
import wrapperStyles from '../BottomZone.module.scss';
import styles from './PopularColumn.module.scss';

const PopularColumn = ({ articles }) => {
  const heading = 'Popular';
  const renderPopularArticles = () => {
    if (isEmpty(articles)) return null;

    const articlesToRender = articles.slice(0, 4);

    return articlesToRender.map((article, i) => {
      return (
        <h3 key={uuidv4()} className={styles['popular-link-container']}>
          <a
            href={uriParameterGenerator(
              article.uri,
              BOTTOM_ZONE_DATA_LAYERS.POPULAR.TEXT,
              i + 1
            )}
            aria-label={article.title}
            className={styles['popular-link']}
          >
            {article.title}
          </a>
        </h3>
      );
    });
  };
  const popularHeadingClasses = classNames(
    wrapperStyles.heading,
    styles['popular-heading-title']
  );
  return (
    <div
      className={styles['popular-column']}
      data-testid="popularColumnContainer"
    >
      <div className={styles['popular-heading']}>
        <h2 className={popularHeadingClasses}>{heading}</h2>
      </div>
      {renderPopularArticles()}
    </div>
  );
};

PopularColumn.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      uri: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default PopularColumn;
