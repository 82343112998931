export const uriParameterGenerator = (uri, abParameter, positionRank = '') => {
  if (!uri || !abParameter) return '';

  const isSecondParameter = uri.includes('?');
  const queryStringInidicator = isSecondParameter ? '&' : '?';
  const rankingPosition = positionRank && `-${positionRank}`;

  return `${uri}${queryStringInidicator}ab=${abParameter}${rankingPosition}`;
};
export const setPositionCounter = (positionCounter) => {
  positionCounter.value = 0; // eslint-disable-line no-param-reassign
  positionCounter.lastProcessedIsImage = false; // eslint-disable-line no-param-reassign
};

export const topicZonePositionCounter = {};

export const handleIncrement = (positionCounter, isImage = false) => {
  const lastItemWasImage = positionCounter.lastProcessedIsImage;
  positionCounter.lastProcessedIsImage = isImage; // eslint-disable-line no-param-reassign

  if (lastItemWasImage) return positionCounter.value;
  positionCounter.value += 1; // eslint-disable-line no-param-reassign
  return positionCounter.value;
};

export const LATEST_DATA_LAYER = {
  TEXT: 'HP-hero-latest-text',
  IMAGE: 'HP-hero-latest-image',
};

export const FOR_YOU_DATA_LAYER = {
  TEXT: 'HP-hero-for-you-text',
  IMAGE: 'HP-hero-for-you-image',
};

export const FEATURED_DATA_LAYER = {
  TEXT: 'HP-hero-featured-text',
  IMAGE: 'HP-hero-featured-image',
};

export const FOLLOWED_ARTICLE_DATA_LAYER = {
  TEXT: 'HP-latest-topic-followed-text',
  IMAGE: 'HP-latest-topic-followed-image',
};

export const DIGITAL_ARTICLE_DATA_LAYER = {
  TEXT: 'HP-latest-text',
  IMAGE: 'HP-latest-image',
};

export const MEDIA_CARD_DATA_LAYER = {
  TEXT: 'HP-latest-text',
  IMAGE: 'HP-latest-image',
};

export const SPONSOR_CARD_DATA_LAYER = {
  TEXT: 'HP-latest-sponsored-text',
  IMAGE: 'HP-latest-sponsored-image',
};

export const MAGAZINE_ZONE_DATA_LAYER = {
  TEXT: 'HP-magazine-text',
  IMAGE: 'HP-magazine-image',
};

export const LIST_BOX_DATA_LAYER = {
  TEXT: 'HP-topics-text',
  IMAGE: 'HP-topics-image',
};

export const SPONSOR_BOX_DATA_LAYER = {
  TEXT: 'HP-topics-sponsored-text',
  IMAGE: 'HP-topics-sponsored-image',
};

export const TOPIC_INSIGHT_CENTER_DATA_LAYER = {
  TEXT: 'HP-topics-insight-center-text',
  IMAGE: 'HP-topics-insight-center-image',
};

export const BOTTOM_ZONE_DATA_LAYERS = {
  POPULAR: {
    TEXT: 'HP-bottom-popular-text',
  },
  NEWSLETTERS: {
    SEE_MORE: 'HP-bottom-newsletter-more',
    TOUT: 'HP-bottom-newsletter-tout',
  },
  HBR_SPECIALS: {
    TEXT: 'HP-bottom-sub-exclusive-text',
    IMAGE: 'HP-bottom-sub-exclusive-image',
  },
  PODCAST: {
    SEE_MORE: 'HP-bottom-podcast-more',
    TEXT: 'HP-bottom-podcast-text',
  },
};
