import { useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { findIndex, map, floor, range } from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  topicZonePositionCounter,
  setPositionCounter,
} from 'domains/DataLayer/DataLayerURIGenerator';
import SVGIcon from 'mfe-core/ui/SVGIcon';
import ComponentLoadFailure from 'components/ComponentLoadFailure/ComponentLoadFailure';
import ContentBox from './ContentBox/ContentBox';
import styles from './TopicZone.module.scss';
import { STATUS_CODE_SUCCESS, VIEW_PORTS } from '../../constants';
import useUserSpecificContent from '../../hooks/useUserSpecificContent';

const PREV_POSITION = 'prev';
const NEXT_POSITION = 'after';
const SPONSORED_ARTICLE = 'Sponsor Content';
const REDIRECT_TO_TOPICS = '/topics';

const TopicZone = ({
  topicItems,
  insightCenterItems,
  sponsorArticles,
  statuses,
}) => {
  const topicsContainer = useRef(null);
  const [active, setActive] = useState();
  const [slidesLeftPositions, setSlidesLeftPostions] = useState();
  const [mobilePaddingLeft, setMobilePaddingLeft] = useState(0);
  const slidesContainer = useRef(null);
  const ITEMS_PER_ROW = 4;
  const MAX_ROWS = 2;

  const setMaxWidthOnMobile = () => {
    const { MOBILE: mobile, MOBILE_MAX_WIDTH: mobileMaxWidth } = VIEW_PORTS;
    let paddingLeft = 0;

    if (window.innerWidth >= mobileMaxWidth && window.innerWidth < mobile) {
      paddingLeft = (window.innerWidth - mobileMaxWidth) / 2;
      setMobilePaddingLeft(paddingLeft);
    } else {
      setMobilePaddingLeft(0);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', setMaxWidthOnMobile);

    setMaxWidthOnMobile();

    return () => window.removeEventListener('resize', setMaxWidthOnMobile);
  }, []);

  const { articles: topicAffinityUserArticles, statuses: userArticleStatuses } =
    useUserSpecificContent({ topicItems }, statuses);
  const [statusSuccess, setStatusSuccess] = useState(true);

  setPositionCounter(topicZonePositionCounter);
  const topicsToRender = useMemo(() => {
    let formattedTopics = [...(topicAffinityUserArticles || [])];

    if (typeof sponsorArticles[1] !== 'undefined') {
      formattedTopics.splice(2, 0, sponsorArticles[1]);
    }

    if (typeof insightCenterItems[0] !== 'undefined') {
      const insightCenterItem = {
        ...insightCenterItems[0],
        isInsightCenter: true,
      };

      formattedTopics.splice(5, 0, insightCenterItem);
    }
    if (typeof insightCenterItems[1] !== 'undefined') {
      const insightCenterItem = {
        ...insightCenterItems[1],
        isInsightCenter: true,
      };

      formattedTopics.splice(7, 0, insightCenterItem);
    }

    formattedTopics = formattedTopics.slice(0, MAX_ROWS * ITEMS_PER_ROW);

    const sponsoredArticlePos = findIndex(formattedTopics, [
      'type',
      SPONSORED_ARTICLE,
    ]);

    const topics = formattedTopics.map((item, index) => {
      // Adds a data-* attribute to the cards before and after
      // the Sponsor Box in order to add custom styling
      let position;

      if (sponsoredArticlePos >= 0) {
        if (index === sponsoredArticlePos - 1) {
          position = PREV_POSITION;
        } else if (index === sponsoredArticlePos + 1) {
          position = NEXT_POSITION;
        }
      }

      return (
        <ContentBox
          key={uuidv4()}
          topic={item}
          position={position}
          index={index}
        />
      );
    });

    return topics;
  }, [topicAffinityUserArticles, insightCenterItems, sponsorArticles]);

  useEffect(() => {
    const slides = slidesContainer?.current?.children;
    const leftPositions = map(slides, (slide) => {
      return slide.getBoundingClientRect().left;
    });

    setSlidesLeftPostions(leftPositions);
  }, []);

  const onScroll = (event) => {
    const leftPosition = event.target.scrollLeft;

    let index = findIndex(slidesLeftPositions, (position) => {
      return floor(leftPosition, -1) === floor(position, -1);
    });

    if (leftPosition > slidesLeftPositions[slidesLeftPositions.length - 2]) {
      index = slidesLeftPositions.length - 1;
    }

    if (index >= 0) {
      setActive(index);
    }
  };

  const getControlClasses = (controlIndex) =>
    classNames([styles['slider-button']], {
      [styles['button-active']]:
        (!active && controlIndex === 0) || active === controlIndex,
    });

  const renderControls = () => {
    const controlsArray = range(MAX_ROWS * ITEMS_PER_ROW);
    const controls = map(controlsArray, (item, index) => (
      <span
        key={uuidv4()}
        className={getControlClasses(index)}
        data-testid="topic-zone__slider-button"
        aria-label={`Scroll to card ${index}`}
      >
        {index}
      </span>
    ));

    return controls;
  };

  useEffect(() => {
    const initialValue = true;
    const currentStatus = userArticleStatuses.reduce(
      (accumulator, statusObj) => {
        return accumulator
          ? statusObj['status-code'] === STATUS_CODE_SUCCESS
          : false;
      },
      initialValue
    );

    setStatusSuccess(currentStatus);
  }, [userArticleStatuses]);

  return (
    <section className={styles['topic-zone']} ref={topicsContainer}>
      {!statusSuccess ? (
        <ComponentLoadFailure />
      ) : (
        <>
          <div
            className={styles.wrapper}
            data-testid="topic-zone__wrapper"
            ref={slidesContainer}
            onScroll={onScroll}
            style={{ paddingLeft: mobilePaddingLeft }}
          >
            {topicsToRender}
          </div>
          <div className={styles.slider}>{renderControls()}</div>
          <a
            href={REDIRECT_TO_TOPICS}
            className={styles['more-topics']}
            aria-label="See more topics"
          >
            More Topics
            <SVGIcon
              className={styles['more-topics-icon']}
              variant={SVGIcon.variants.ARROW_RIGHT_NAV}
            />
          </a>
        </>
      )}
    </section>
  );
};

TopicZone.propTypes = {
  topicItems: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.shape({
        text: PropTypes.string,
      }),
      contents: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired,
          uri: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          image: PropTypes.shape({
            sizes: PropTypes.string,
            srcset: PropTypes.string,
            uri: PropTypes.string,
          }),
        })
      ),
    })
  ).isRequired,
  insightCenterItems: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.shape({
        text: PropTypes.string,
      }),
      contents: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string.isRequired,
          uri: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          image: PropTypes.shape({
            sizes: PropTypes.string,
            srcset: PropTypes.string,
            uri: PropTypes.string,
          }),
        })
      ),
    })
  ).isRequired,
  sponsorArticles: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      dek: PropTypes.string.isRequired,
      image: PropTypes.shape({
        sizes: PropTypes.string,
        srcset: PropTypes.string,
        uri: PropTypes.string,
      }),
      topic: PropTypes.shape({
        name: PropTypes.string.isRequired,
        uri: PropTypes.string.isRequired,
      }),
    })
  ).isRequired,
  statuses: PropTypes.arrayOf(
    PropTypes.shape({
      'status-code': PropTypes.number,
      message: PropTypes.string,
    })
  ).isRequired,
};

export default TopicZone;
