import { findIndex } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import ContentCard from './ContentCard/ContentCard';

import styles from './LatestRow.module.scss';

const PREV_POSITION = 'prev';
const NEXT_POSITION = 'after';
const SPONSORED_ARTICLE = 'Sponsor Content';

const LatestRow = ({ articles: latestArticles }) => {
  const renderLatestArticles = () => {
    const sponsoredArticlePos = findIndex(latestArticles, [
      'type',
      SPONSORED_ARTICLE,
    ]);

    const articles = latestArticles.map((article, index) => {
      // Adds a data-* attribute to the cards before and after
      // the Sponsored Article Card in order to add custom styling
      let position;

      if (sponsoredArticlePos >= 0) {
        if (index === sponsoredArticlePos - 1) {
          position = PREV_POSITION;
        } else if (index === sponsoredArticlePos + 1) {
          position = NEXT_POSITION;
        }
      }

      return (
        <ContentCard key={uuidv4()} article={article} position={position} />
      );
    });

    return articles;
  };

  return <div className={styles['latest-row']}>{renderLatestArticles()}</div>;
};

LatestRow.defaultProps = {
  articles: [],
};

LatestRow.propTypes = {
  articles: PropTypes.instanceOf(Array),
};

export default LatestRow;
