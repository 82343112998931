import PropTypes from 'prop-types';
import styles from './ExitDraftMode.module.scss';

const ExitDraftMode = ({ redirectTo }) => {
  return (
    <a
      className={styles.link}
      href={`/api/mfe-home/draft-mode/disable?return=${redirectTo}`}
    >
      EXIT PREVIEW MODE
    </a>
  );
};

ExitDraftMode.propTypes = {
  redirectTo: PropTypes.string.isRequired,
};

export default ExitDraftMode;
