import PropTypes from 'prop-types';
import ListBox from './ListBox/ListBox';
import SponsorBox from './SponsorBox/SponsorBox';

const ContentBox = ({ topic, position, index }) => {
  const renderBox = (item) => {
    if (item.type === 'Sponsor Content') {
      return <SponsorBox article={item} index={index} />;
    }
    return <ListBox topic={item} position={position} index={index} />;
  };

  return renderBox(topic);
};

ContentBox.propTypes = {
  topic: PropTypes.shape({
    heading: PropTypes.shape({
      text: PropTypes.string.isRequired,
    }),
    contents: PropTypes.arrayOf(
      PropTypes.shape({
        uri: PropTypes.string.isRequired,
        type: PropTypes.string,
        image: PropTypes.shape({
          uri: PropTypes.string.isRequired,
        }),
      })
    ),
    cta: PropTypes.shape({
      text: PropTypes.string.isRequired,
      image: PropTypes.shape({
        uri: PropTypes.string.isRequired,
      }),
    }),
  }).isRequired,
  position: PropTypes.string,
  index: PropTypes.number.isRequired,
};

ContentBox.defaultProps = {
  position: undefined,
};

export default ContentBox;
