import { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import LazyLoadingImage from 'components/LazyLoadingImage/LazyLoadingImage';
import { base64ThumbnailImage } from 'constants';
import styles from './Thumbnail.module.scss';

const TestIds = {
  CONTAINER: 'thumbnail-container',
};

const Thumbnail = ({
  alt,
  image,
  className,
  errorClassName,
  redirectTo,
  lazyLoad,
  srcset,
  cover,
  sizes,
}) => {
  const [loadingError, setLoadingError] = useState(false);
  const thumbnailImage = base64ThumbnailImage;

  const onError = () => {
    setLoadingError(true);
  };

  useEffect(() => {
    if (isEmpty(image.uri)) {
      onError(true);
    }
  }, [image]);

  const renderImage = () => {
    if (loadingError)
      return (
        <div className={errorClassName} data-testid={TestIds.CONTAINER}>
          <a
            className={styles.link}
            href={redirectTo}
            aria-label={alt && `Read more about ${alt}`}
          >
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img className={styles.error} src={thumbnailImage} alt={alt} />
          </a>
        </div>
      );

    return (
      <LazyLoadingImage
        alt={alt}
        src={image.uri}
        className={className}
        errorClassName={errorClassName}
        redirectTo={redirectTo}
        onLoadingError={onError}
        lazyLoad={lazyLoad}
        srcset={srcset}
        sizes={sizes}
        cover={cover}
      />
    );
  };

  return renderImage();
};

Thumbnail.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.shape({
    uri: PropTypes.string,
  }),
  redirectTo: PropTypes.string,
  className: PropTypes.string,
  errorClassName: PropTypes.string,
};

Thumbnail.defaultProps = {
  image: {
    uri: '',
  },
  alt: '',
  redirectTo: '',
  className: '',
  errorClassName: '',
  lazyLoad: true,
  srcset: null,
  cover: false,
  sizes: null,
};

export default Thumbnail;
export { TestIds as ThumbnailTestIds };
