import arrowsDesktop from '../../assets/images/SubscriberTout/arrows/arrows_desktop.png';
import arrowsMobile from '../../assets/images/SubscriberTout/arrows/arrows_mobile.png';
import pipesDesktop from '../../assets/images/SubscriberTout/pipes/pipes_desktop.gif';
import pipesMobile from '../../assets/images/SubscriberTout/pipes/pipes_mobile.gif';
import character1Desktop from '../../assets/images/SubscriberTout/characters/character_1_desktop.png';
import character1Mobile from '../../assets/images/SubscriberTout/characters/character_1_mobile.png';
import character2Desktop from '../../assets/images/SubscriberTout/characters/character_2_desktop.png';
import character2Mobile from '../../assets/images/SubscriberTout/characters/character_2_mobile.png';
import character3Desktop from '../../assets/images/SubscriberTout/characters/character_3_desktop.png';
import character3Mobile from '../../assets/images/SubscriberTout/characters/character_3_mobile.png';
import character4Desktop from '../../assets/images/SubscriberTout/characters/character_4_desktop.png';
import character4Mobile from '../../assets/images/SubscriberTout/characters/character_4_mobile.png';

const SUBSCRIBER_TOUT_SLIDES = [
  {
    header: 'Get the Best in Management Thinking',
    cta: 'Subscribe',
    cta_link:
      'https://hbr.org/subscriptions?ab=home-subtout&tpcc=houseads.site.home-subtout1',
    whiteContrast: true,
    desktopBackground: arrowsDesktop,
    mobileBackground: arrowsMobile,
  },
  {
    header: 'Get the Best in Management Thinking',
    cta: 'Subscribe',
    cta_link:
      'https://hbr.org/subscriptions?ab=home-subtout&tpcc=houseads.site.home-subtout2',
    whiteContrast: false,
    desktopBackground: pipesDesktop,
    mobileBackground: pipesMobile,
  },
  {
    header: 'Get the Best in Management Thinking',
    cta: 'Subscribe',
    cta_link:
      'https://hbr.org/subscriptions?ab=home-subtout&tpcc=houseads.site.home-subtout3',
    whiteContrast: false,
    desktopBackground: character1Desktop,
    mobileBackground: character1Mobile,
  },
  {
    header: 'Get the Best in Management Thinking',
    cta: 'Subscribe',
    cta_link:
      'https://hbr.org/subscriptions?ab=home-subtout&tpcc=houseads.site.home-subtout4',
    whiteContrast: false,
    desktopBackground: character2Desktop,
    mobileBackground: character2Mobile,
  },
  {
    header: 'Get the Best in Management Thinking',
    cta: 'Subscribe',
    cta_link:
      'https://hbr.org/subscriptions?ab=home-subtout&tpcc=houseads.site.home-subtout5',
    whiteContrast: false,
    desktopBackground: character3Desktop,
    mobileBackground: character3Mobile,
  },
  {
    header: 'Get the Best in Management Thinking',
    cta: 'Subscribe',
    cta_link:
      'https://hbr.org/subscriptions?ab=home-subtout&tpcc=houseads.site.home-subtout6',
    whiteContrast: false,
    desktopBackground: character4Desktop,
    mobileBackground: character4Mobile,
  },
];

export default SUBSCRIBER_TOUT_SLIDES;
