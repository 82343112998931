import { useMemo } from 'react';
import { isEmpty } from 'lodash';
import ComponentLoadFailure from 'components/ComponentLoadFailure/ComponentLoadFailure';
import Thumbnail from 'components/Thumbnail/Thumbnail';
import PropTypes from 'prop-types';
import {
  uriParameterGenerator,
  FEATURED_DATA_LAYER,
} from 'domains/DataLayer/DataLayerURIGenerator';
import useImageAspectRatio from '../../../hooks/useImageAspectRatio';
import { formatAuthors, stripMarkupFromString } from '../../../utilities';
import styles from './Featured.module.scss';
import { STATUS_CODE_SUCCESS } from '../../../constants';

const featuredImageSizes =
  '(min-width: 1210px) 565px, (min-width: 1025px) 47vw, (min-width: 818px) 70vw, 100vw';

const Featured = ({ article }) => {
  const { windowHeight } = useImageAspectRatio();
  const formattedAuthors = useMemo(
    () => formatAuthors(article.authors),
    [article.authors]
  );

  const formattedDek = useMemo(
    () => stripMarkupFromString(article.dek),
    [article.dek]
  );

  const imageToRender = !isEmpty(article.thumbnail?.uri)
    ? article.thumbnail
    : article.image;

  return (
    <div className={styles['featured-content']}>
      {article['response-status']['status-code'] !== STATUS_CODE_SUCCESS ? (
        <ComponentLoadFailure />
      ) : (
        <>
          <div
            className={styles['image-container']}
            style={{ height: `${windowHeight}px` }}
          >
            <Thumbnail
              alt={article.title}
              image={imageToRender}
              srcset={imageToRender?.srcset}
              sizes={featuredImageSizes}
              redirectTo={uriParameterGenerator(
                article.uri,
                FEATURED_DATA_LAYER.IMAGE,
                1
              )}
              className={styles['featured-image']}
              errorClassName={styles['fixed-image']}
              cover
              lazyLoad={false}
            />
          </div>

          <div className={styles.article}>
            {!isEmpty(article.topic) && (
              <span className={styles.topic}>
                <a
                  href={uriParameterGenerator(
                    article.topic.uri,
                    FEATURED_DATA_LAYER.TEXT,
                    1
                  )}
                  className={styles.link}
                  aria-label={`Read more about ${article.topic.name}`}
                >
                  {article.topic.name}
                </a>
              </span>
            )}

            <h1 className={styles.title}>
              <a
                href={uriParameterGenerator(
                  article.uri,
                  FEATURED_DATA_LAYER.TEXT,
                  1
                )}
                className={styles.link}
                data-element-type="for-you-element-hover"
                aria-label={`Read more about ${article.title}`}
              >
                {article.title}
              </a>
            </h1>

            {formattedDek && (
              <p className={styles.dek}>
                <a
                  href={uriParameterGenerator(
                    article.uri,
                    FEATURED_DATA_LAYER.TEXT,
                    1
                  )}
                  className={styles.link}
                >
                  {formattedDek}
                </a>
              </p>
            )}

            {!isEmpty(formattedAuthors) && (
              <span className={styles.authors}>
                <a
                  href={uriParameterGenerator(
                    article.uri,
                    FEATURED_DATA_LAYER.TEXT,
                    1
                  )}
                  className={styles.link}
                >
                  {formattedAuthors}
                </a>
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
};

Featured.imageSizes = featuredImageSizes;

Featured.propTypes = {
  article: PropTypes.shape({
    'response-status': PropTypes.shape({
      'status-code': PropTypes.number,
      message: PropTypes.string,
    }).isRequired,
    type: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    dek: PropTypes.string,
    authors: PropTypes.arrayOf(PropTypes.string).isRequired,
    image: PropTypes.shape({
      srcset: PropTypes.string,
      uri: PropTypes.string.isRequired,
    }),
    thumbnail: PropTypes.shape({
      srcset: PropTypes.string,
      uri: PropTypes.string.isRequired,
    }),
    topic: PropTypes.shape({
      name: PropTypes.string,
      uri: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default Featured;
