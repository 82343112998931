import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './LazyLoadingImage.module.scss';

const TestIds = {
  CONTAINER: 'lazy-img-container',
};

const LazyLoadingImage = ({
  src,
  srcset,
  sizes,
  alt,
  redirectTo,
  withWrapper,
  className,
  errorClassName,
  cover,
  onLoadingError,
  lazyLoad,
}) => {
  const [shouldLoad, setShouldLoad] = useState(!lazyLoad);
  const [errorLoadingImage, setErrorLoadingImage] = useState(false);
  const placeholderRef = useRef(null);

  useEffect(() => {
    if (!shouldLoad && placeholderRef.current) {
      // https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserverEntry/intersectionRatio
      const observer = new IntersectionObserver(([{ intersectionRatio }]) => {
        if (intersectionRatio > 0) {
          setShouldLoad(true);
        }
      });

      observer.observe(placeholderRef.current);
      return () => observer.disconnect();
    }
    return undefined;
  }, [shouldLoad, placeholderRef]);

  const onError = () => {
    setErrorLoadingImage(true);

    if (onLoadingError) {
      onLoadingError();
    }
  };

  const lazyClasses = classNames({
    [styles['lazy-cover']]: cover,
    [styles.lazy]: !cover,
  });

  const containerClasses = classNames({
    [errorClassName]: errorLoadingImage || !src,
    [className]: !(errorLoadingImage || !src),
  });

  const renderImage = () => {
    if (errorLoadingImage || !shouldLoad || !src) {
      return <div className={styles['lazy-default']} ref={placeholderRef} />;
    }

    return (
      // eslint-disable-next-line @next/next/no-img-element
      <img
        className={lazyClasses}
        src={src}
        srcSet={srcset}
        sizes={sizes}
        onError={onError}
        alt={alt}
      />
    );
  };

  if (!withWrapper) {
    return renderImage();
  }

  return (
    <div className={containerClasses} data-testid={TestIds.CONTAINER}>
      <a
        className={styles.link}
        href={redirectTo}
        aria-label={alt && `Read more about ${alt}`}
      >
        {renderImage()}
      </a>
    </div>
  );
};

LazyLoadingImage.propTypes = {
  src: PropTypes.string,
  srcset: PropTypes.string,
  sizes: PropTypes.string,
  alt: PropTypes.string.isRequired,
  redirectTo: PropTypes.string,
  withWrapper: PropTypes.bool,
  className: PropTypes.string,
  errorClassName: PropTypes.string,
  cover: PropTypes.bool,
  onLoadingError: PropTypes.func,
  lazyLoad: PropTypes.bool,
};

LazyLoadingImage.defaultProps = {
  src: '',
  redirectTo: '',
  withWrapper: true,
  className: '',
  errorClassName: '',
  cover: false,
  onLoadingError: null,
  lazyLoad: true,
  srcset: null,
  sizes: null,
};

export default LazyLoadingImage;
export { TestIds as LazyLoadingImageTestIds };
