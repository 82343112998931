import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import ComponentLoadFailure from 'components/ComponentLoadFailure/ComponentLoadFailure';
import classNames from 'classnames';
import NewsletterTout from './NewsletterTout';
import LatestCard from './LatestCard';
import styles from './TheLatest.module.scss';
import {
  STATUS_CODE_SUCCESS,
  LATEST_COLUMN_ARTICLES_TO_DISPLAY as MAX_ARTICLES,
} from '../../../constants';

const TheLatest = ({ head, articles: latestArticles, status }) => {
  const renderArticlesColumn = () => {
    const articlesToRender = latestArticles.slice(0, MAX_ARTICLES);
    const newsletterTout = <NewsletterTout key={uuidv4()} />;
    const articles =
      status['status-code'] !== STATUS_CODE_SUCCESS ? (
        <ComponentLoadFailure />
      ) : (
        articlesToRender.map((article, index) => (
          <LatestCard
            key={uuidv4()}
            article={article}
            positionRank={index + 1}
          />
        ))
      );

    return [articles, newsletterTout];
  };

  const latestClasses = classNames({
    [styles.articles]: status['status-code'] === STATUS_CODE_SUCCESS,
    [styles['articles-fail']]: status['status-code'] !== STATUS_CODE_SUCCESS,
  });
  return (
    <div className={styles.latest}>
      <h1 className={styles.title}>{head}</h1>
      <div className={latestClasses}>{renderArticlesColumn()}</div>
    </div>
  );
};

TheLatest.defaultProps = {
  articles: [],
};

TheLatest.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      dek: PropTypes.string,
      authors: PropTypes.arrayOf(PropTypes.string),
      image: PropTypes.shape({
        uri: PropTypes.string,
      }),
    })
  ),
  head: PropTypes.string.isRequired,
  status: PropTypes.shape({
    'status-code': PropTypes.number,
    message: PropTypes.string,
  }).isRequired,
};

export default TheLatest;
