import { isEmpty } from 'lodash';
import Thumbnail from 'components/Thumbnail/Thumbnail';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import {
  uriParameterGenerator,
  SPONSOR_BOX_DATA_LAYER,
  handleIncrement,
  topicZonePositionCounter,
} from 'domains/DataLayer/DataLayerURIGenerator';
import classNames from 'classnames';
import { stripMarkupFromString } from '../../../../utilities';
import styles from './SponsorBox.module.scss';

const SponsorBox = ({ article, index }) => {
  const formattedDek = useMemo(
    () => stripMarkupFromString(article.dek),
    [article.dek]
  );

  const imageToRender = !isEmpty(article.thumbnail?.uri)
    ? article.thumbnail
    : article.image;

  const sponsorClasses = classNames(styles['content-box'], styles.sponsor);
  const thumbnailClasses = classNames(
    styles['topic-image'],
    styles['sponsor-image']
  );
  const slugClasses = classNames(styles.slug, styles['sponsor-slug']);
  const linkClasses = classNames(
    styles['slug-link'],
    styles['sponsor-slug-link']
  );
  const topicLinkClasses = classNames(
    styles['topic-title-link'],
    styles['sponsor-topic-title-link']
  );

  return (
    <div className={styles['sponsor-container']} id={`content-box-${index}`}>
      <div className={sponsorClasses}>
        <Thumbnail
          alt={article.title}
          image={imageToRender}
          redirectTo={uriParameterGenerator(
            article.uri,
            SPONSOR_BOX_DATA_LAYER.IMAGE,
            handleIncrement(topicZonePositionCounter, true)
          )}
          className={thumbnailClasses}
        />

        {article.type && (
          <span className={slugClasses}>
            <a
              href={article.uri}
              className={linkClasses}
              aria-label={`Read more about ${article.type}`}
            >
              {article.type}
            </a>
          </span>
        )}

        <div className={styles.dek}>
          <h3
            className={styles['topic-title']}
            data-element-type="content-box-element-hover"
          >
            <a
              className={topicLinkClasses}
              href={uriParameterGenerator(
                article.uri,
                SPONSOR_BOX_DATA_LAYER.TEXT,
                handleIncrement(topicZonePositionCounter)
              )}
              aria-label={`Read more about ${article.title}`}
            >
              {article.title}
            </a>
          </h3>
          <span className={styles['sponsor-slug-dek']}>{formattedDek}</span>
        </div>
      </div>
    </div>
  );
};

SponsorBox.propTypes = {
  article: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string.isRequired,
    uri: PropTypes.string.isRequired,
    dek: PropTypes.string.isRequired,
    topic: PropTypes.shape({
      name: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }).isRequired,
    image: PropTypes.shape({
      uri: PropTypes.string.isRequired,
    }),
    thumbnail: PropTypes.shape({
      uri: PropTypes.string.isRequired,
    }),
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default SponsorBox;
